import Head from 'next/head';

import useBrandConfigStore from '@/context/store/useBrandConfigStore.store';

export const metadata = {
	title: 'Home',
	openGraph: {
		title: 'Home'
	}
};

function HeadLayout() {
	const { brandConfig } = useBrandConfigStore((state) => ({
		brandConfig: state.config
	}));
	return (
		<>
			<Head>
				<link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
				{/* preconnect */}
				{/* <link rel="preconnect" href={process.env.NEXT_PUBLIC_IMAGE_DOMAIN} crossOrigin />
				<link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_IMAGE_DOMAIN} nonce />
				<link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin />
				<link rel="dns-prefetch" href="https://www.googletagmanager.com" nonce />
				<link rel="preconnect" href="https://www.google-analytics.com" crossOrigin />
				<link rel="dns-prefetch" href="https://www.google-analytics.com" nonce /> */}
				{/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
				{/* <meta
										name="viewport" 
										content="width=device-width, initial-scale=1.0, minimum-scale=1.0"></meta> */}
				<meta httpEquiv="cache-control" content="max-age=172800" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"></meta>
				<title>{brandConfig?.websiteTitle}</title>
				<meta name="robots" content="/sitemap.xml" />
				<meta content={brandConfig?.websiteDescription} name="description" />
				{/* canonical url */}
				<link rel="canonical" href={brandConfig?.canonicalUrl} />
				{/* Open Graph */}
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content={brandConfig?.brandName} />
				<meta property="og:description" content={brandConfig?.websiteDescription} />
				<meta property="og:title" content={brandConfig?.websiteTitle} />
				<meta property="og:url" content={brandConfig?.websiteUrl} />
				<meta property="og:image" name="image" content={brandConfig?.brandLogo} />
				{/* Twitter */}
				<meta name="twitter:card" content="summary_large_image" />
				{/* <meta name="twitter:site" content="" /> */}
				<meta name="twitter:title" content={brandConfig?.websiteTitle} />
				<meta name="twitter:description" content={brandConfig?.websiteDescription} />
				<meta name="twitter:image" content={brandConfig?.brandLogo} />
				<meta
					name="msapplication-TileColor"
					content={brandConfig?.themeConfig?.container.backgroundColor.primary}
				/>
				<meta
					name="theme-color"
					content={brandConfig?.themeConfig?.container.backgroundColor.primary}
				/>

				<script
					dangerouslySetInnerHTML={{
						__html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`
					}}
				/>
			</Head>
		</>
	);
}

export default HeadLayout;
